import { createAxiosByinterceptors } from "@/api/request";
import url from "@/constant/url";
const vueAppEnv = process.env.VUE_APP_ENV;

const request = createAxiosByinterceptors({
  baseURL: "https://cqygdqkj.top/api/v1/",
});

//查询列表
export const getMachineNum = (params: any): Promise<any> => request.get(`zqwb/machine_info/?page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询列表
export const searchMachine = (params: any): Promise<any> => request.get("zqwb/machine_info/?search=" + params); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询二维码
export const getMachineQrcode = (params: any): Promise<any> => request.get(`zqwb/qrcode_info/?id=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//删除列表记录
export const deleteMachineNum = (params: any): Promise<any> => request.delete("zqwb/machine_info/" + params); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//上传设备
export const upload = (params: any): Promise<any> => request.post("zqwb/blue_info/", params); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//获取用户列表
export const getUser = (params: any): Promise<any> => request.get(`zqwb/user_info/?page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询用户列表
export const queryUser = (params: any): Promise<any> => request.get("zqwb/user_info/?search=" + params); // 查询参数是手机号

//删除用户
export const deleteUser = (params: any): Promise<any> => request.post("zqwb/user_delete/", params); // 删除为{id:"xxx"}

//修改用户密码
export const changeUser = (params: any): Promise<any> => request.post("zqwb/user_password/", params); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//修改用户权限
export const changeRole = (param: any): Promise<any> => request.put(`zqwb/user_permission/${param.user}/`, param);

//上传更新包
export const updateInfo = (param: any): Promise<any> => request.post("zqwb/update_info/", param, { headers: { "Content-Type": "application/form-data" } });

//获取绑定列表
export const getbindNum = (params: any): Promise<any> => request.get(`zqwb/user_bind_info/?is_checked=false&page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询绑定列表
export const searchBind = (params: any): Promise<any> => request.get(`zqwb/user_bind_info/?is_checked=false&search=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//上传表格设备
export const updateExcel = (param: any): Promise<any> => request.post("zqwb/update_info/", param, { headers: { "Content-Type": "application/form-data" } });

//审核用户绑定申请
export const bindInfo = (param: any): Promise<any> => request.put(`zqwb/user_bind_info/${param.id}`, param);

//查询故障列表
export const searchError = (params: any): Promise<any> => request.get(`zqwb/fault_report/?page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//审核故障状态
export const ErrorInfo = (param: any): Promise<any> => request.put(`zqwb/fault_report/${param.id}`, param);

//查询日志列表
export const searchLog = (params: any): Promise<any> => request.get(`user/log_info/?page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询说明列表
export const searchexp = (params: any): Promise<any> => request.get(`/zqwb/app_desc/all/`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//修改说明
export const changeexp = (params: any): Promise<any> => request.put(`zqwb/app_desc/${params.id}`,params); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true

//查询版本列表
export const searchversion = (params: any): Promise<any> => request.get(`/zqwb/update_info/?page=${params}`); // 不需要默认的全局loading效果可配置loading为false关闭 loading默认为true



// export const pageMenus = (params: any): Promise<any> =>
//   request.get("/appPage/menusTree", {
//     params,
//     loading: true,
//   });?page=${params}

// export const exportGoods = (data: any) =>
//   request.post("/promo_gateway_item_b2b/1.0/export", data, {
//     responseType: "blob",
//   });
