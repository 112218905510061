
  import { Vue, Component } from "vue-property-decorator";
  import dayjs from "dayjs";
  import { searchLog} from "@/api/modules/getmachine";
  @Component({
    name: "log",
  })
  export default class Eqmanagement extends Vue {
    private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
    private form = {search:""};
    private queryparam = "";
    private iframe = (input: HTMLElement | null): input is HTMLIFrameElement =>
     input !== null && input.tagName === 'IFRAME';

    
  
    private async mounted() {
    //   let datas = [];
    //   for (let i = 0; i < 400; i++) {
    //     datas.push({
    //       date: dayjs().format("YYYY-MM-DD"),
    //       name: `姓名${i}`,
    //       address: `地址${i}`,
    //     });
    //   }
    //   this.tableData = datas;
      let res =await searchLog(1)
      this.tableData = res.list;
      this.pageSize = res.total
      //this.pageNo = res.page_count
      console.log("默认第一页",res)
    }
  
    private handleSizeChange(val: number) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageNo = 1;
    }
  
    private handleCurrentChange(val: number) {
      this.pageNo = val;
      console.log(`当前页: ${val}`);
      let str = this.queryparam?"&search="+this.queryparam:""
      let param = val+ str
      console.log(`当前字符串: ${param}`);
      searchLog((param)).then((res)=>{
        this.tableData = res.list;
        this.pageSize = res.total
       })
       
    }
  
    //打开二维码
    
    private removeIframe(){
    const iframeEL=document.querySelector('.target-el-iframe');
	if(iframeEL){
    iframeEL.remove();
	}
}

private print(){
	this.removeIframe();
	//目标元素
	const info=document.querySelector('.target-el');
	//创建iframe
	const iframeEl= document.createElement('iframe');
	iframeEl.className='target-el-iframe';
	//将元素放置最底层，防止其覆盖了其他元素
	iframeEl.style.position="fixed"
	iframeEl.style.zIndex="-99";
	//添加到页面
	document.querySelector('body')?.append(iframeEl)
	
	const documentEl=iframeEl.contentDocument;
	//深度拷贝目标元素，如果不拷贝，则会将目标元素移动到iframe中
	documentEl?.body.append(info?.cloneNode(true)||"")
	
	//去除打印的页眉和页脚
	const style=document.createElement('style')
	style.media="print";
	style.innerText=`
	 @page {
	  size: auto;
	  margin: 0mm;
	}`
	//添加答应内容
	documentEl?.head.append(style);
	//打印
	iframeEl.contentWindow?.print()
}

    
    

   
  }
  